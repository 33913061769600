const channelId =
  typeof process.env.REACT_APP_CHANNEL_ID !== 'undefined'
    ? parseInt(process.env.REACT_APP_CHANNEL_ID, 10)
    : 1;

const isSecondTheme = channelId === 5;

export const config = {
  routes: {
    favourites: {
      path: process.env.REACT_APP_FAV_PATH || '/favoriter',
    },
    blog: {
      path: process.env.REACT_APP_BLOG_PATH || '/inspiration',
      id: process.env.REACT_APP_BLOG_ID || 1830,
      mainLevel: 1,
      menu: true,
    },
    brand: {
      id: [148, 827, 1262],
    },
    category: {
      hide: false,
    },
    product: {
      hide: false,
    },
  },
  settings: {
    isSecondTheme: isSecondTheme,
    channelId: channelId,
    storeName: isSecondTheme ? 'Barnkalaset' : 'Kalaskungen',
    siteContentId: isSecondTheme ? 116 : 115,
    siteContentIdBelgium: 140,
    channels: {
      'KK-SE': {
        storeName: 'Kalaskungen',
        siteContentId: 115,
        favicon: '/favicon.ico',
        logo: 'kalaskungen-logo.png',
        klarnaId: 'a87521bd-a2c6-5092-8561-aa022c3c6a72',
        klarnaLocale: 'sv-SE',
        domain: 'kalaskungen.com',
        addwishActive: true,
      },
      'KK-BE': {
        storeName: 'Kalaskungen',
        siteContentId: 115,
        favicon: '/favicon.ico',
        logo: 'kalaskungen-logo-be.png',
        klarnaId: '150ee397-d239-5d69-b257-01b85b0a12fe',
        klarnaLocale: 'nl-BE',
        domain: false,
        addwishActive: false,
      },
      'KK-DE': {
        storeName: 'Kalaskungen',
        siteContentId: 115,
        favicon: '/favicon.ico',
        logo: 'kalaskungen-logo-de.png',
        klarnaId: '150ee397-d239-5d69-b257-01b85b0a12fe',
        klarnaLocale: 'de-DE',
        domain: false,
        addwishActive: true,
      },
      'KK-DK': {
        storeName: 'Kalaskongen',
        siteContentId: 115,
        favicon: '/favicon.ico',
        logo: 'kalaskungen-logo-dk.png',
        klarnaId: '9cda6eb0-a281-5450-9185-acc4f173583a',
        klarnaLocale: 'da-DK',
        domain: false,
        addwishActive: true,
      },
      'KK-FI': {
        storeName: 'Synttarikuningas',
        siteContentId: 115,
        favicon: '/favicon.ico',
        logo: 'kalaskungen-logo-fi.jpg',
        klarnaId: 'fb39544f-bdeb-5bde-b7de-040965f46835',
        klarnaLocale: 'fi-FI',
        domain: false,
        addwishActive: true,
      },
      'KK-NL': {
        storeName: 'Kalaskungen',
        siteContentId: 115,
        favicon: '/favicon.ico',
        logo: 'kalaskungen-logo-nl.png',
        klarnaId: '17fb9a6a-4873-54ff-bb6e-2e1838164f61',
        klarnaLocale: 'nl-NL',
        domain: false,
        addwishActive: false,
      },
      'KK-NO': {
        storeName: 'Bursdagskongen',
        siteContentId: 115,
        favicon: '/favicon.ico',
        logo: 'kalaskungen-logo-no.jpg',
        klarnaId: '502f052b-f780-5ed9-99b8-74d890a8b7b5',
        klarnaLocale: 'no-NO',
        domain: 'bursdagskongen.com',
        addwishActive: true,
      },
      'BK-SE': {
        storeName: 'Kalaskungen',
        siteContentId: 116,
        favicon: '/pub_images/original/favicon-barnkalaset-32x32.png',
        logo: 'barnkalaset-logo.png',
        klarnaId: false,
        klarnaLocale: false,
        domain: false,
        addwishActive: true,
      },
    },
  },
};

export function getChannel(name) {
  if (config.settings.channels[name]) return config.settings.channels[name];
  return config.settings.channels['KK-SE'];
}

export function isBenede(name) {
  return name === 'KK-BE' || name === 'KK-NL' || name === 'KK-DE';
}
