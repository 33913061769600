import React, { useContext, useEffect } from 'react';
import { styled } from 'linaria/react';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import { getChannel } from '../../../shop.config.custom';

const KlarnaWrapper = styled('div')`
  klarna-placement iframe {
    max-width: unset !important;
    display: flex;
    justify-content: center;
    > div {
      width: auto !important;
    }
  }
`;

const KlarnaOnSite = () => {
  const { selectedChannel } = useContext(ChannelContext);
  const channel = getChannel(selectedChannel.name);
  useEffect(() => {
    if (!window.KlarnaOnsiteService) {
      var tag = document.createElement('script');
      tag.async = true;
      tag.src = 'https://eu-library.klarnaservices.com/lib.js';
      tag.setAttribute('data-client-id', channel.klarnaId);
      var body = document.getElementsByTagName('body')[0];
      body.appendChild(tag);
    }

    window.KlarnaOnsiteService = window.KlarnaOnsiteService || [];
    if (window.Klarna?.OnsiteMessaging) window.Klarna.OnsiteMessaging.refresh();
    else window.KlarnaOnsiteService.push({ eventName: 'refresh-placements' });
  });

  return (
    <KlarnaWrapper>
      {/***
       *
       * This should be generated and customized in
       *  Klarna Mecharnt Portal
       * https://playground.eu.portal.klarna.com/on-site-messaging/dashboard/installation
       *
       ***/}
      <klarna-placement
        data-key="footer-promotion-auto-size"
        data-locale={channel.klarnaLocale}
      ></klarna-placement>
    </KlarnaWrapper>
  );
};

export default KlarnaOnSite;
