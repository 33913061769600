import React, { useContext } from 'react';
import { styled } from 'linaria/react';
import NewsletterField from './NewsletterField';
import Image from '@jetshop/ui/Image/Image';
import { theme } from '../../../Theming/Theming';
import SiteContentContext from '../../../SiteContent/SiteContentContext';

const NewsletterBoxWrapper = styled('div')`
  position: relative;
  .newsletter-box-wrapper {
    text-align: center;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    ${theme.above.lg} {
      padding: 2rem;
    }
  }
  &:not(.image) {
    padding: 1rem;
    background: #f9f9f9;
    display: flex;
    flex-direction: column;
    > div {
      width: 100%;
    }
    ${theme.above.lg} {
      padding: 1rem;
      flex-direction: row;
      > div {
        width: 50%;
      }
    }
  }
  &.image {
    .image-wrapper [data-flight-image-container] {
      ${theme.below.sm} {
        padding-bottom: 80% !important;
      }
      ${theme.above.xl} {
        padding-bottom: 20% !important;
      }
    }
    .newsletter-box-wrapper {
      .text {
        width: 100%;
        h1,
        h2,
        h3,
        h4,
        h5,
        p,
        a {
        }
      }
      .newsletter-wrapper {
        input {
          background: white;
          border-color: #333;
        }
        button {
          border-color: #333;
        }
        ${theme.below.sm} {
          form {
            flex-wrap: wrap;
            button {
              width: 100%;
              margin-left: 0;
              margin-top: 5px;
            }
          }
        }
      }
      position: absolute;
      background: white;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      width: calc(100% - 2rem);
      max-width: 900px;
      padding: 1.5rem 4rem;
      ${theme.above.lg} {
        padding: 2rem 6rem;
      }
      ${theme.below.sm} {
        padding: 2rem 1rem;
      }
    }
  }
`;

const NewsletterBox = () => {
  const { showNewsletter, newsletter, newsletterImage } =
    useContext(SiteContentContext);

  if (!showNewsletter) return null;
  return (
    <NewsletterBoxWrapper className={newsletterImage ? 'image' : ''}>
      {newsletterImage && (
        <div className="image-wrapper">
          <Image
            aspect={'25:10'}
            sizes={newsletterImage ? [1, 1, 1, 1] : [1, 1, 1 / 2, 1 / 2]}
            src={newsletterImage}
            alt={newsletterImage}
            crop={true}
            cover
          />
        </div>
      )}
      <div className="newsletter-box-wrapper">
        <div className="text">{newsletter}</div>
        <NewsletterField />
      </div>
    </NewsletterBoxWrapper>
  );
};

export default NewsletterBox;
